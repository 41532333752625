<template>
  <div class="index">
    <Nav />
    <TeamPage />
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
import TeamPage from "@/components/teamPage.vue";
export default {
  name: "Team",
  components: {
    Nav,
    TeamPage,
    Footer
  },
  metaInfo: {
    title: "SeismicCore | Our Team",
    meta: [
      { name: "theme-color", content: "#57b3fd" },
      { property: "og:title", content: "SeismicCore | Our Team" },
      {
        property: "og:description",
        content: "Meet the team at SeismicCore."
      },
      {
        name: "description",
        content: "Meet the team at SeismicCore."
      },
      { name: "twitter:title", content: "SeismicCore | Our Team" },
      {
        name: "twitter:description",
        content: "Meet the team at SeismicCore."
      }
    ]
  }
};
</script>
<style>
@import "../assets/css/main.css";
</style>
